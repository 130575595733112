<template lang="pug">
div
		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else)
			Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogOpcoesImpressao.visible")
				.ta-center.my-2
					ProgressSpinner.waitingImprimir(v-if='dialogOpcoesImpressao.waiting' strokeWidth="6")
					div(v-else)
						Button.p-button-info.mr-2(label='Impressão térmica' icon='jam jam-ticket' @click='imprimirGuias(true)')
						Button.p-button-success(label='Impressão A4' icon='jam jam-printer' @click='imprimirGuias()')
			ConfirmDialog(
			:display="dialogRemoverPagamento.visible"
			@confirmed="removerFormadePagamento(dialogRemoverPagamento.idx, dialogRemoverPagamento.elem)"
			@close="dialogRemoverPagamento.visible = false")
				template(#text-content="props")
					span Deseja mesmo <b>remover</b> essa forma de pagamento?

			Dialog.dialogPagamento(header='Adicionar forma de pagamento' :modal='true' :visible.sync='dialogPagamentoVisible' @hide='() => { this.model.valor_pago = 0; this.model.cd_forma_pagamento = null; this.model.nr_credito_parcelas = null; onHide()}')
				.p-grid.p-fluid
					.p-col-6
						label.form-label Método:
						Dropdown(
							v-model='model.cd_forma_pagamento'
							:options='options.formasPagamento'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							@change='valor_a_adicionar = 0'
						)
					.p-col-6
						label.form-label Valor a pagar:
						CurrencyInput(
							v-model='valor_a_adicionar'
							locale='pt-BR'
							:auto-decimal-mode='true'
							:value-range='{ min: 0 }')

					.p-col-6(v-if='model.cd_forma_pagamento == 1')
						label.form-label Valor recebido:
						CurrencyInput(
							v-model='model.valor_pago'
							locale='pt-BR'
							:auto-decimal-mode='true'
							:value-range='{ min: 0 }')

					.p-col-6(v-if='model.cd_forma_pagamento == 1')
						label.form-label Troco:
						CurrencyInput(
							:value='troco'
							disabled)

					.p-col-12(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6 || model.cd_forma_pagamento == 3')
						Panel(toggleable :collapsed='!modelManual.ie_registro_manual' @toggle='manualTEF')
							template(#header)
								.title-header-panel
									i.jam.jam-credit-card
									span Inserir manualmente:

							.p-grid
								//- .p-col-12
								//- 	Message(severity="warn" :closable='false')
								//- 		span(style='font-size: 12px; text-align: center') Preecher somente se houver falha de integração com TEF.
								.p-col-6
									label.form-label Operadora:
									Dropdown(
										v-model='modelManual.cd_operadora'
										:options='options.operadoras'
										optionLabel='ds_operadora'
										optionValue='id'
										dataKey='id'
									)
								.p-col-6(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6')
									label.form-label Bandeira:
									Dropdown(
										:disabled='!modelManual.cd_operadora'
										v-model='modelManual.ie_bandeira'
										:options='options.bandeiras[modelManual.cd_operadora]'
										optionLabel='ds_valor'
										optionValue='ie_valor'
										dataKey='ie_valor'
									)
								.p-col-6(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6')
									label.form-label Últimos 4 dígitos do cartão:
									InputText(v-model='modelManual.nr_final_cartao' :useGrouping='false')

								.p-col-6(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6')
									label.form-label Código de autorização:
									InputText(v-model='modelManual.cd_autorizacao'  :useGrouping='false')

								.p-col-6(v-if='model.cd_forma_pagamento == 3')
									label.form-label DOC :
									InputText(v-model='modelManual.nr_pix_doc'  :useGrouping='false')

					.p-col-6(v-if='model.cd_forma_pagamento == 5 && modelManual.ie_registro_manual')
						label.form-label Parcelas:
						Dropdown(
							v-model='model.nr_credito_parcelas'
							:options='ie_cobrar_cliente ? options.parcelas : options.parcelasCredishop '
							optionLabel='text'
							:disabled='(model.nr_valor_adicionar == 0 || modelManual.ie_bandeira == null) && (modelManual.ie_registro_manual)'
							optionValue='value'
							dataKey='value'
							class='parcelas'
						)
							template(#option='props')
								div.p-dropdown-item
										span <b> {{props.option.text}}</b><br>
										span {{formatPrice(props.option.valor_total)}}

					.p-col-6(v-if='model.cd_forma_pagamento == 5 && !modelManual.ie_registro_manual')
						label.form-label Parcelas:
						Dropdown(
							v-model='model.nr_credito_parcelas'
							:options='options.parcelas'
							optionLabel='text'
							:disabled='model.nr_valor_adicionar == 0'
							optionValue='value'
							dataKey='value'
							class='parcelas'
						)
							template(#option='props')
								div.p-dropdown-item
										span <b> {{props.option.text}}</b><br>
										span {{formatPrice(props.option.valor_total)}}
					.p-col-6(v-if='model.cd_forma_pagamento == 5')
						label.form-label Valor recebido:
						CurrencyInput.input-adicionar(
							:value='valor_juros'
							disabled
							locale='pt-BR'
							:auto-decimal-mode='false'
							:value-range='{ min: 0 }')

					.p-col-12.ta-center
						Button(label='Adicionar' icon='jam jam-plus' @click='adicionar()')

			Dialog.dialogFinalizar(v-if='$refs.buscarPaciente' header='Confirmação de pagamento' :modal='true' :visible.sync='dialogFinalizar')
				div(v-if='$refs.buscarPaciente.model && totalDebito === totalPago()')
					h1.ta-center Resumo

					p <b>Pagante: </b> {{ $refs.buscarPaciente.model.nm_pessoa_fisica }}
					p <b>CPF: </b>{{ $refs.buscarPaciente.model.nr_cpf_f }}
					div(v-if='list.length > 1')
						p <b>Formas de pagamento:</b>
						ul
							li(v-for='elem, idx in list' :key='idx')
								p <b>{{ elem.cd_forma_pagamento_f }}: </b>{{ elem.nr_valor_f }}
					div(v-else-if='list.length === 1')
						p <b>Forma de pagamento:</b> {{ list[0].cd_forma_pagamento_f }}
						p <b>Valor:</b> {{ list[0].nr_valor_f }}
					div(v-else)
						p <b>Valor:</b> R$ 0,00

					.ta-center.my-4
						ProgressSpinner(v-if='waitingFinalizar' strokeWidth='6')
						Button.p-button-success(v-else label='Confirmar' icon='jam jam-check' @click='finalizar()')

			Dialog.dialogPagamento.ta-center(:modal='true' :visible.sync='criandoAtendimento' :showHeader='false' :closable='false')
				h4 Criando Atendimento
				ProgressSpinner(strokeWidth='6' :style="{'height':'5em'}")
			Dialog.dialogPagamento.ta-center(:modal='true' :visible.sync='waitingPagamentoAprovado' :showHeader='false' :closable='false' )
				div(style="height: 200px; display: flex; flex-direction: column; justify-content: center, align-items: center ")
					h3 Aguarde...
					ProgressSpinner(strokeWidth='6' :style="{'height':'6em'}")

			Dialog.dialogUserNoCpf(header='Usuário sem CPF' :modal='true' :visible.sync='dialogUserNoCpf' :showHeader='true')
					p(style="margin-top:0px; margin-bottom:25px;") Pagante não possui CPF cadastrado. Para prosseguir, adicione um CPF ou troque o pagante.
					div(style='display: flex; justify-content: center; margin-bottom: 15px')
						Button.p-button(label='Atualizar Cadastro' @click="addCpf()" style='margin-right: 15px') 	
												
			Panel.datatable(header='Lista de guias')
				ProgressBar(v-if='waitingResumo' mode='indeterminate')
				.ta-center(v-else-if='!selected.length') Nenhum horário encontrado
				span(v-else)
					DataTable(:value="selected")
						Column(headerStyle='width: 25%;' field='nm_paciente' header='Paciente' :sortable="true")
							template(#body='props')
								div
									//- esse verifica se é dependente
									span(v-if='props.data.cd_paciente.cd_pai') {{ props.data.cd_paciente.nm_pessoa_fisica }} (Dependente)
									//- esse verifica se é titular
									span(v-else-if='props.data.nr_cpf') {{ props.data.nm_paciente }} (Titular)
									Button.ml-2(v-if='props.data.cd_paciente.cd_pai' style='font-size:10px;float: right; white-space:nowrap;' icon='jam jam-check'  v-tooltip.top="'Selecionar como pagante'" @click='selecionarPagante(props.data)')
						Column(headerStyle='width: 29%;' header='CPF')
							template(#body='props')
								div
									//- esse verifica se é dependente	
									span(v-if='props.data.cd_paciente.cd_pai') {{ props.data.cd_paciente.cd_pai.nr_cpf_f }} (Titular) <br> 
									span(v-if='props.data.cd_paciente.cd_pai' style="font-size: 12px;") {{ props.data.cd_paciente.cd_pai.nm_pessoa_fisica }} 
									//- esse verifica se é titular
									span(v-else-if='props.data.nr_cpf') {{ props.data.nr_cpf_f }} 
									Button.ml-2(style='font-size:10px; float: right;' icon='jam jam-check' v-tooltip.top="'Selecionar como pagante'" @click='selecionarPagante(props.data.cd_paciente.cd_pai || props.data)')
						Column(headerStyle='width: 20%;' field='nm_estabelecimento' header='Estabelecimento')
						Column(headerStyle='width: 28%;' header='Procedimento(s)')
							template(#body='props')
								div(v-if='props.data.ds_procedimento') <span class="label-qtd">{{props.data.nr_quantidade}}x</span> {{props.data.ds_procedimento}} <b v-if='props.data.nm_subgrupo'> | SUBGRUPO: {{ props.data.nm_subgrupo }} </b>
								div(v-else)
									p(v-for='i in props.data.cd_procedimentos' :key='i.id')
										span &bull; <span class="label-qtd">{{i.nr_quantidade}}x</span> {{ i.nm_procedimento }}
						Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valor_total_f' header='Valor')
						Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valor_total_antigo_f' header='Valor antigo')
						Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' header='Usar valor antigo?' v-if='!$parent.imprimir')
							template(#body='props')
								.ta-center
									Checkbox(v-model='props.data.ie_antigo' :binary="true" @input='')
						Column(headerStyle='width: 8%; text-align: center' v-if='$parent.imprimir')
							template(#header) Ações
							template(#body='props')
								.ta-center.box-imprimir
									ProgressSpinner(v-if='waitingImprimirGuia == props.data.id' strokeWidth='6')
									Button.p-button-raised.p-button-rounded.mr-1(
										:class="{ 'p-button-secondary': memGuiasImpressas[props.data.id] == true }"
										v-else
										type='button'
										v-tooltip.top="'Imprimir guia'"
										icon="jam jam-printer"
										@click='dialogOpcoesImpressao.cd_horario_agendamento=props.data.id; dialogOpcoesImpressao.visible=true'
									)

					.box-total
						p <b>Total: <span class='span-total'>{{ formatPrice(totalDebito) }}</span></b>
					.box-imprimir-todas.ta-right.mt-2(v-if='$parent.imprimir')
						ProgressSpinner(v-if='waitingImprimirTodasGuias' strokeWidth='6')
						Button.btn-finalizar(
							v-else type='button' label='Imprimir todas' icon="jam jam-printer"
							@click='dialogOpcoesImpressao.visible=true')

			.p-grid.p-fluid.mt-2(v-if='! $parent.imprimir && selected.length')
				.p-col-10.p-lg-10.p-md-9.p-sm-12.p-xs-9
					BuscarPaciente(ref='buscarPaciente' label='Pagante' :somenteTitular='false' @titular='atualizaCredito' @waiting='waitBuscarPaciente = $event')
				.p-col-2.p-lg-2.p-md-3.p-sm-3.p-xs-3
					label.form-label Créditos MedClub:
					ProgressBar.mt-4(v-if='waitBuscarPaciente' mode='indeterminate')
					InputText.ta-right(v-else disabled type='text' readonly :value='formatPrice(creditoPagante)' :style="{color: '#3c60ba', fontWeight: 'bold', opacity:'1'}")
				.p-col-12.mt-2
					BuscarPaciente(ref='buscarPaciente2' label='Indicação' :somenteTitular='true' :hideCadastro='dialogCadastro' :somenteIndicador='true')
			Panel.mt-2(header='Formas de pagamento' v-if='list.length')
				.p-grid.p-fluid
					.p-col-12.p-md-3(v-for='elem, idx in list' :key='idx')
						Card.payment-box
							template(slot='content')
								.p-grid.p-fluid
									.p-col-6
										.ta-left(v-if='elem.comprovanteAdquirente' v-tooltip.top="'Imprimir comprovante'")
												a.btn-remove-payment(href='javascript:;' @click='imprimirComprovantePagamento(elem.comprovanteAdquirente)')
													i.jam.jam-printer
									.p-col-6
										.ta-right(v-if='elem.cd_forma_pagamento != 3 || elem.ie_registro_manual' v-tooltip.top="'Cancelar'")
											a.btn-remove-payment(href='javascript:;' @click='openDialogRemoverPagamento(idx, elem)')
												i.jam.jam-close
										
								.ta-center
									div.icon-box(v-if='elem.cd_forma_pagamento == 1')
										i.pi.pi-money-bill
									div.icon-box(v-if='elem.cd_forma_pagamento == 5')
										i.jam.jam-credit-card
									div.icon-box(v-if='elem.cd_forma_pagamento == 6')
										i.jam.jam-id-card
									div.icon-box(v-if='elem.cd_forma_pagamento == 3')
										i.jam.jam-envelope
									div.icon-box(v-if='elem.cd_forma_pagamento == 4')
										i.jam.jam-phone
									p.text-method {{ elem.cd_forma_pagamento_f }}
									p.text-value {{ elem.nr_valor_f }}
									p.text-parcelas(v-if='elem.nr_credito_parcelas') Total: {{ formatPrice(elem.valor_total) }}

			.ta-right.mt-4(v-if='selected.length')
				span.info-resta(v-if='totalPago() > 0 && totalPago() < totalDebito') (Resta {{ formatPrice(totalDebito - totalPago()) }})
				Button.btn-margin.p-button-warning(
					v-if='!$parent.imprimir'
					label='Retornar ao Carrinho'
					icon="jam jam-shopping-cart"
					@click="voltaCarrinho()")
				Button.btn-margin(
					v-if='totalPago() < totalDebito'
					label='Forma de pagamento'
					icon="jam jam-plus"
					v-tooltip.top="!this.$refs.buscarPaciente?.cd_pessoa_fisica && 'Selecione um pagante'"
					:disabled='!this.$refs.buscarPaciente?.cd_pessoa_fisica'
					@click="$refs.buscarPaciente.model.nr_cpf ? (model.nr_valor_adicionar = 0, dialogPagamento = true) : dialogUserNoCpf = true"
					)
				div(v-else-if='$parent.imprimir')
					.p-grid
						.p-col-6.ta-left
							Button.btn-finalizar.p-button-warning(
								label='Retornar'
								type='button'
								icon="jam jam-log-out"
								@click='$router.go(0)'
								)
						.p-col-6
							.mr-4(v-if='waitingImprimirTermica' style='display:inline-block;vertical-align:middle')
								ProgressSpinner(strokeWidth='4')
							Button.btn-margin.btn-finalizar.p-button-warning.mr-2(
								v-else
								label='Imprimir térmica'
								icon="jam jam-newspaper"
								type='button'
								@click='imprimirTermica()'
								)
							Button.btn-margin.btn-finalizar(
								label='Imprimir comprovante'
								icon="jam jam-printer"
								type='button'
								@click='imprimirComprovante()'
								)
					Imprimir(ref='boxImprimir' :id='this.transacaoId')
				Button.btn-margin.btn-finalizar.p-button-success(
					v-else-if='$refs.buscarPaciente?.cd_pessoa_fisica'
					label='Finalizar'
					icon="jam jam-check"
					@click='dialogFinalizar = true'
					)
</template>

<style lang="scss">
	.caixa-listarGuias {
		.label-qtd{
			display:inline-block;
			padding:5px;
			background:#EEE;
			border-radius:5px;
			border:1px solid #CCC;
		}
		.p-panel-titlebar {
			.title-header-panel {
				span {
					margin-left: 10px;
				}
				width: 50%;
				display: flex;
				align-items: center;
			}
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.waitingImprimirTermica {
			width: 25px;
			height: auto;
		}
		.p-progress-spinner {
			height: 32px;
		}
		.box-imprimir {
			.p-progress-spinner {
				height: 29px;
				width: 100%;
			}
		}
		.box-imprimir-todas {
			.p-progress-spinner {
				height: 34px;
				width: 34px;
			}
		}
		.box-total {
			border: 1px solid #c8c8c8;
			border-top: none;
			text-align: right;
			padding: 0.857em;
			background-color: #f4f4f4;
			.span-total {
				width: 13.5%;
				margin-left: 1%;
				display: inline-block;
				text-align: center;
			}
			p {
				margin: 0;
			}
		}
		.dialog-opcoes-impressao {
			width: 500px;
			max-width: 96%;
		}
		.dialogPagamento {
			width: 96%;
			max-width: 500px;
			.p-inputnumber-input {
				text-align: right;
			}
			.p-dialog-content {
				overflow: visible !important;
			}
			.input-adicionar p-inputnumber-input p-inputtext p-component p-filled  p-component:disabled{
			opacity: 1;
			}
		}
		.dialogFinalizar {
			width: 96%;
			max-width: 400px;
			h1 {
				font-size: 16px;
			}
			p {
				margin: 2px 0;
			}
		}

		.dialogUserNoCpf {
			width: 96%;
			max-width: 650px;
		}
		.payment-box {
			min-height:280px;
			.text-method {
				font-size: 18px;
				margin: 0;
				margin-top: 14px;
			}
			.text-value {
				font-size: 22px;
				font-weight: 700;
				margin: 0;
				margin-top: 10px;
			}
			.text-parcelas{
				font-size: 15px;
				margin-top: 14px;
			}
			.icon-box {
				width: 100px;
				height: 100px;
				line-height: 130px;
				border-radius: 50%;
				display: inline-block;
				background-color: #312c66;
				i { font-size: 44px; color: #fff; }
			}
			.btn-remove-payment {
				display: inline-block;
				background-color: #c8c8c8;
				width: 20px;
				height: 20px;
				text-align: center;
				line-height: 16px;
				border-radius: 50%;
				i { font-size: 20px; color: #666; }
			}
		}
		.info-resta {
			font-size: 20px;
			margin-right: 20px;
			font-weight: 700;
			color: #888;
		}
		.btn-finalizar {
			font-size: 16px !important;
		}
		.__box-imprimir {
			visibility: hidden;
		}
		.imprimir-transacao {
			@media screen {
				display: none;
			}
		}
		.parcelas{
			.p-dropdown-item{
				display: inline-grid;
				width: 100%;
				word-wrap: break-word;
				white-space: initial;
				.p-highlight{
					color: white !important;
				}
			}
		}
		.btn-margin {
			margin: 1em 0 0 1em;
		}
	}
</style>

<script>
	import Button from 'primevue/button'
	import Panel from 'primevue/panel'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Dialog from 'primevue/dialog'
	import Dropdown from 'primevue/dropdown'
	import InputNumber from 'primevue/inputnumber'
	import InputText from 'primevue/inputtext'
	import ProgressBar from 'primevue/progressbar'
	import Card from 'primevue/card'
	import ProgressSpinner from 'primevue/progressspinner'
	import BuscarPaciente from './../Paciente/BuscarPaciente'
	import Tooltip from 'primevue/tooltip'
	import Imprimir from './../TransacoesFinanceiras/Imprimir'
	import Accordion from 'primevue/accordion';
	import AccordionTab from 'primevue/accordiontab';
	import InputMask from 'primevue/inputmask';
	import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog";
	import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
	import Checkbox from 'primevue/checkbox';
	import Message from 'primevue/message';
	import Cadastro from './../Paciente/BuscarPaciente-cadastro';
	import _ from 'lodash'

	import { Agendamento, Caixas, TransacaoCartao } from '../../middleware'

    export default {
		created () {
			this.waiting = true
			Caixas.getFormasPagamento().then(response => {
				this.waiting = false
				if (response.status == 200) {
					if (response.data.length) {
						response.data.forEach(elem => {
							this.options.formasPagamento.push({ value: elem.id, text: elem.nm_forma_pagamento })
						})
						this.model.cd_forma_pagamento = ''
					}
				}
			})
			Caixas.checkCaixaAtivo().then(response => {
				if (response.status == 200) {
					this.defaultManual = !response.data.id_terminal_paygo
					this.modelManual.ie_registro_manual = !response.data.id_terminal_paygo
				}
			})

			if ( this.cd_atendimento )	{
				TransacaoCartao.findAll({cd_atendimento: this.cd_atendimento, ie_situacao: 'PA'}).then(response => {
					response.data.forEach(item => {

						this.list.push({
                            cd_forma_pagamento: this.options.formasPagamento.find(elem => elem.value == item.cd_forma_pagamento)?.value,
                            cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == item.cd_forma_pagamento)?.text,
                            nr_valor: Number(item.nr_valor),
                            nr_valor_f: this.formatPrice(item.nr_valor),
                            nr_credito_parcelas: item.installments,
                            ie_registro_manual: item.ie_registro_manual,
                            valor_total : item.amount,
                            cd_transacao_cartao: item.id,
							comprovanteAdquirente: item.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
                        })
                    })
				})
			}
			Caixas.getjuros().then(response => {
				if (response.status == 200) {
					this.options.parcelas = _.cloneDeep(response.data)
					this.options.parcelasCredishop = _.cloneDeep(response.data)
				}
			})
			// DominioValor.findAll({ds_mnemonico: ["FATOR_CIELO_PARCELAS", "BANDEIRA_CREDITO"]}).then(response => {
			// 	if(([200, 201]).includes(response.status))
			// 		this.options.parcelas = response.data["FATOR_CIELO_PARCELAS"].valores.filter(item => item.ie_status);
			// 	})
		},
		computed: {
			dialogPagamentoVisible: {
				get() {
					return this.dialogPagamento && !!this.cd_atendimento;
				},
				set(val) {
					this.dialogPagamento = val;
				}
			},
            troco() {
                return this.model.valor_pago && this.valor_a_adicionar ? +this.model.valor_pago - +this.valor_a_adicionar : 0
            },
            valor_a_adicionar:{
                get() {
                    const resto = this.totalDebito - this.totalPago();
                    if(this.model.valor_pago > resto && this.model.nr_valor_adicionar == 0) return resto;
                    return this.model.nr_valor_adicionar;
                },
                set(val){ this.model.nr_valor_adicionar = val; }
            },
            valor_juros() {
				if(this.model.nr_credito_parcelas) {
					if(this.ie_cobrar_cliente || !this.modelManual.ie_registro_manual)
						return +this.model.nr_valor_adicionar * +this.options.parcelas.find(item => item.value == +this.model.nr_credito_parcelas).nr_juros
					else {
						return +this.model.nr_valor_adicionar * 1
					}
                }
				return 0;
            },
			totalDebito () {
                let sum = 0
                if (this.selected){
                    this.selected.forEach(elem => {
                        sum += elem.ie_antigo ? elem.nr_valor_total_antigo : elem.nr_valor_total
                    })
                }
                sum = sum.toFixed(2)
                return parseFloat(sum)
            },
		},
		watch: {
			'cd_atendimento': function(val) {
				if (val) {
					TransacaoCartao.findAll({cd_atendimento: this.cd_atendimento, ie_situacao: 'PA'}).then(response => {
						response.data.forEach(item => {
							this.list.push({
								cd_forma_pagamento: this.options.formasPagamento.find(elem => elem.value == item.cd_forma_pagamento)?.value,
								cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == item.cd_forma_pagamento)?.text,
								nr_valor: Number(item.nr_valor),
								nr_valor_f: this.formatPrice(item.nr_valor),
								nr_credito_parcelas: item.installments,
								ie_registro_manual: item.ie_registro_manual,
								valor_total : item.amount,
								cd_transacao_cartao: item.id
							})
						})

					})
				}
			},
			"model.cd_forma_pagamento": function(value) {
				if(value) {
					Caixas.getBandeirasOperadoras({cd_forma_pagamento: value}).then(response => {
						this.options.operadoras = response.data
						response.data.forEach(item => {
							this.options.bandeiras[item.id] = item.cd_bandeiras
					})
			})
				}

			},
			"model.nr_valor_adicionar": function(valor){
				if(valor == 0)
					this.model.nr_credito_parcelas = null;
				else {
					this.options.parcelasCredishop.forEach(item => {
						item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * 1 / +item.nr_parcela)}`
						item.value = +item.nr_parcela
						item.valor_total = this.model.nr_valor_adicionar * 1
					})
					this.options.parcelasCredishop = this.options.parcelasCredishop.filter(i => i.value <= 6)

					this.options.parcelas.forEach(item => {
						item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * +item.nr_juros / +item.nr_parcela)}`
						item.value = +item.nr_parcela
						item.valor_total = this.model.nr_valor_adicionar * +item.nr_juros
					})

					// this.options.parcelas.forEach(item => {
					// 	item.text = `${item.nr_parcela} X ${this.formatPrice(valor * +item.nr_juros / +item.nr_parcela)}`
					// 	item.value = +item.nr_parcela
					// 	item.valor_total = valor * +item.nr_juros
					// })
				}
			},
			"modelManual.ie_registro_manual": function() {
				this.ie_cobrar_cliente = true
			},
			"modelManual.cd_operadora": function(valor){
				this.modelManual.ie_bandeira = null
				this.ie_cobrar_cliente = this.options.operadoras.find(item => item.id === valor)?.ie_cobrar_cliente
				if(!this.options.operadoras.find(item => item.id === valor)?.ie_cobrar_cliente) {
					this.options.parcelasCredishop.forEach(item => {
							item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * 1 / +item.nr_parcela)}`
							item.value = +item.nr_parcela
							item.valor_total = this.model.nr_valor_adicionar * 1
					})
					this.options.parcelasCredishop = this.options.parcelasCredishop.filter(i => i.value <= 6)
				} else {
					this.options.parcelas.forEach(item => {
						item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * +item.nr_juros / +item.nr_parcela)}`
						item.value = +item.nr_parcela
						item.valor_total = this.model.nr_valor_adicionar * +item.nr_juros
					})
				}
				if(this.model.nr_credito_parcelas != null)
					this.$toast.info('As taxas mudaram, selecione as parcelas novamente', { duration: 6000 })
				this.model.nr_credito_parcelas = null
			},
            dialogPagamento: async function(val) {
                if (!this.cd_atendimento && val)
                    await this.criarAtendimento()

            //     setTimeout(() => { let el = document.querySelector('.input-adicionar');
			// 		if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
			// 		{ this.model.nr_valor_adicionar = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
			// 		el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }
			// 	})

			// 	setTimeout(() => { let el = document.querySelector('.input-adicionar');
			// 		if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
			// 		{ this.model.nr_valor_adicionar = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
			// 		el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }
			// 	})
			}
		},
		components: { Button, Panel, DataTable, Column, Dialog, Dropdown, Tooltip, Cadastro,
			InputNumber, ProgressBar, Card, BuscarPaciente, ProgressSpinner, Imprimir, Accordion, AccordionTab, InputMask, InputText, ConfirmDialog, Message, CurrencyInput, Checkbox},
		directives: { tooltip: Tooltip },
		props: ['cd_atendimento_id', 'waitingResumo', 'selected', 'cd_titular'],
		data () {
			return {
				totalPago () {
					let sum = 0
					this.list.forEach(elem => sum += elem.nr_valor)
					sum = sum.toFixed(2)
					return parseFloat(sum)
				},
				list: [],
				model: {
					cd_forma_pagamento: null,
					nr_valor_adicionar: 0,
					valor_pago: 0,
					nr_credito_parcelas: null,
				},
				modelManual: {
					cd_pagante: null,
					cd_atendimento: null,
					cd_forma_pagamento: null,
					nr_valor: 0,
					qt_parcelas: 1,
					ie_registro_manual: false,
					cd_operadora: null,
					ie_bandeira: null,
					nr_final_cartao: '',
					nr_inicio_cartao: '',
					cd_autorizacao: '',
					nr_pix_doc: ''
				},
				defaultManual: true,
				options: {
					formasPagamento: [],
					parcelas: [],
					parcelasCredishop: [],
					bandeiras: {},
					operadoras: []
				},
				dialogPagamento: false,
				dialogFinalizar: false,
				dialogUserNoCpf: false,
				dialogCadastro: false,
				dialogRemoverPagamento:{
					visible: false,
					idx: null,
					elem: null,
				},
				dialogOpcoesImpressao: {
					visible: false,
					cd_horario_agendamento: null,
					waiting: false,
				},
				comprovantePagamentoImprimir: null,
				valoresAntigosList: {},
				waiting: false,
				waitingFinalizar: false,
				waitingImprimirGuia: 0,
				waitingImprimirTodasGuias: false,
				waitingImprimirTermica: false,
				waitingPaciente: false,
				waitBuscarPaciente: false,
				waitingPagamentoAprovado: false,
				waitingCancelamento: false,
				cd_transacao_cartao : null,
				creditoPagante: 0,
				transacaoId: 0,
				ie_cobrar_cliente : false,
				memGuiasImpressas: {},
				criandoAtendimento: false,
				cd_atendimento: this.cd_atendimento_id,
			}
		},
		methods: {
			checkedTest(data, value) {
				this.valoresAntigosList[data.id] = value
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			manualTEF(args) {
				this.modelManual['ie_registro_manual'] = !args.value
			},
			selecionarPagante (data) {
				this.$refs.buscarPaciente.limpar()
				if( data.cd_paciente != undefined && data.cd_paciente.cd_pai ){
					this.$refs.buscarPaciente.filtro.selected.by = 'id'
					this.$refs.buscarPaciente.filtro.selected.value = String(data.cd_paciente.id)
				} else {
					this.$refs.buscarPaciente.filtro.selected.value = data.nr_cpf_f
				}

				this.$refs.buscarPaciente.buscar()
			},
			async adicionar () {
				if (this.model.nr_valor_adicionar <= 0) {
					this.$toast.error('Valor deve ser superior a R$ 0,00', { duration: 3000 })
					return
				} else if (this.model.nr_valor_adicionar > (this.totalDebito - this.totalPago()).toFixed(2)) {
					this.$toast.error('Valor digitado maior que o valor restante', { duration: 3000 })
					return
				} else if (!this.model.cd_forma_pagamento) {
					this.$toast.error('Selecione a Forma de Pagamento', { duration: 3000 })
					return
				} else if (!this.$refs.buscarPaciente.cd_pessoa_fisica) {
					this.$toast.error('Selecione o Pagante', { duration: 3000 })
					return
				} else if(this.model.cd_forma_pagamento == 5 && this.model.nr_credito_parcelas == null) {
					this.$toast.error('Selecione as parcelas', { duration: 3000 })
					return
				}

                if([1, 4].includes(this.model.cd_forma_pagamento)) {
                    this.list.push({
                        cd_forma_pagamento: this.model.cd_forma_pagamento,
                        cd_forma_pagamento_f: this.options.formasPagamento.find(
                            elem => elem.value === this.model.cd_forma_pagamento
                        )?.text,
                        nr_valor: this.model.nr_valor_adicionar,
                        nr_valor_f: this.formatPrice(this.model.nr_valor_adicionar),
                        nr_credito_parcelas: this.model.nr_credito_parcelas,
                    })
                    this.dialogPagamento = false
                    return
                }

                this.modelManual.cd_pagante = this.$refs.buscarPaciente.cd_pessoa_fisica
                this.modelManual.cd_atendimento = this.cd_atendimento
                this.modelManual.cd_forma_pagamento = this.model.cd_forma_pagamento
                this.modelManual.nr_valor = this.valor_a_adicionar
                this.modelManual.qt_parcelas = this.model.nr_credito_parcelas ? this.model.nr_credito_parcelas : 1
				if(this.modelManual.ie_registro_manual) {
                    if([5, 6].includes(this.model.cd_forma_pagamento)) {
                        this.modelManual.nr_final_cartao = (this.modelManual.nr_final_cartao || "").replace(/\D/g, '')
                        if (this.modelManual.nr_final_cartao.length !== 4) {
                            this.$toast.error('Informe os 4 últimos dígitos do cartão', { duration: 3000 })
                            return
                        }
                    }
					this.modelManual.cd_autorizacao = this.modelManual.cd_autorizacao || ''
					this.modelManual.nr_pix_doc = this.modelManual.nr_pix_doc || ''
				}

                this.waitingPagamentoAprovado = true
                try {
					const response = await Caixas.pagamentoTEF(this.modelManual)
					if(![200, 201].includes(response.status)) {
						throw new Error(response.data.detail)
					}
					const vendaAprovada = await this.loopConsultarVenda(response.data.id)

					if(vendaAprovada) {
						this.list.push({
							cd_forma_pagamento: this.model.cd_forma_pagamento,
							cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == this.model.cd_forma_pagamento).text,
							nr_valor: this.model.nr_valor_adicionar,
							ie_registro_manual: response.data.ie_registro_manual,
							nr_valor_f: this.formatPrice(this.model.nr_valor_adicionar),
							nr_credito_parcelas: this.model.nr_credito_parcelas,
							valor_total : this.model.cd_forma_pagamento == 5 ? this.valor_juros : null,
							cd_transacao_cartao: this.cd_transacao_cartao,
							comprovanteAdquirente: this.comprovantePagamentoImprimir
						})
						if(this.comprovantePagamentoImprimir) this.imprimirComprovantePagamento(this.comprovantePagamentoImprimir)
						this.dialogPagamento = false
						this.$toast.success('Forma de pagamento adicionada', { duration: 3000 })
					}
				} catch (error) {
					console.log(error)
					this.$toast.error(error, { duration: 3000 })
					this.waitingPagamentoAprovado = false
				}
			},
			async loopConsultarVenda(cd_transacao_cartao) {
				return await new Promise((resolve) => {
				//De 1 em 1s, é feita uma consulta na API do medclub para verificar o andamento da venda
				const id = setInterval(async () => {
				let venda = await Caixas.pagamentoTEFSituacao(cd_transacao_cartao);
				if(venda.status != 200) {
					this.$toast.error("Sem conexão com o servidor. Entre em contato com o administrador do sistema.", { duration: 3000 })
					resolve(false)
				}
				/*
					CÓDIGOS RESPOSTAS:
					5	Pendente
					6	EmPagamento
					10	Creditado
					15	Expirado
					18	CancelamentoIniciado
					19	EmCancelamento
					20	Cancelado
					25	PagamentoRecusado

					Mais informações: https://devpaygo.readme.io/v4/docs/apis-transacional
				*/

				// Pagamento em Andamento
				/* if (!["5", "6"].includes(venda.data.status)) {
					clearInterval(id);
				} */

				// Pagamento Creditado
				if (venda.data.status == "10") {
					this.comprovantePagamentoImprimir = venda.data.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
					this.waitingPagamentoAprovado = false
					this.cd_transacao_cartao = cd_transacao_cartao;
					clearInterval(id)
					resolve(true);
				}

				// Pagamento Cancelado ou Recusado
				if (["15", "18", "19", "20", "25"].includes(venda.data.status)) {
					this.waitingPagamentoAprovado = false
					this.$toast.error('O pagamento foi cancelado ou recusado', { duration: 5000 })
					clearInterval(id)
					resolve(false);
				}
				}, 1500);
				})
			},
			async loopConsultarVendaCancelada(cd_transacao_cartao) {
				return await new Promise((resolve) => {
				//De 1 em 1s, é feita uma consulta na API do medclub para verificar o andamento da venda
				const id = setInterval(async () => {
				let venda = await Caixas.pagamentoTEFSituacao(cd_transacao_cartao);
				/*
					CÓDIGOS RESPOSTAS:
					5	Pendente
					6	EmPagamento
					10	Creditado
					15	Expirado
					18	CancelamentoIniciado
					19	EmCancelamento
					20	Cancelado
					25	PagamentoRecusado

					Mais informações: https://devpaygo.readme.io/v4/docs/apis-transacional
				*/

				// Pagamento em Andamento
				/* if (!["5", "6"].includes(venda.data.status)) {
					clearInterval(id);
				} */

				// Pagamento Creditado
				if (venda.data.status == "20") {
					this.comprovantePagamentoImprimir = venda.data.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
					this.waitingPagamentoAprovado = false
					this.cd_transacao_cartao = cd_transacao_cartao;
					clearInterval(id)
					resolve(true);
				}

				// Pagamento Cancelado ou Recusado
				if (["15", "18", "10", "25"].includes(venda.data.status)) {
					this.waitingPagamentoAprovado = false
					this.$toast.error('Não foi possivel realizar o cancelamento', { duration: 5000 })
					clearInterval(id)
					resolve(false);
				}
				}, 1500);
				})
			},
			onHide() {
				this.dialogPagamento = false
				this.modelManual = {
					cd_pagante: null,
					cd_atendimento: null,
					cd_forma_pagamento: null,
					nr_valor: 0,
					qt_parcelas: 1,
					ie_registro_manual: this.defaultManual,
					cd_operadora: null,
					ie_bandeira: null,
					nr_final_cartao: '',
					nr_inicio_cartao: '',
					cd_autorizacao: '',
					nr_pix_doc: ''
				}
			},
			openDialogRemoverPagamento(idx, elem) {
				this.dialogRemoverPagamento.idx = idx
				this.dialogRemoverPagamento.elem = elem
				this.dialogRemoverPagamento.visible = true
			},
			async removerFormadePagamento(idx, elem) {
				this.waitingCancelamento = true
				if(elem.cd_forma_pagamento == 1 || elem.cd_forma_pagamento == 4) {
					this.list.splice(idx, 1)
					this.dialogRemoverPagamento.visible = false
					this.$toast.success('Forma de pagamento removida', { duration: 3000 })
                    this.waitingCancelamento = false
				} else {
					if(!elem.ie_registro_manual) {
						try {
							await Caixas.removePagamentoTEF(elem.cd_transacao_cartao)
							const cancelado = await this.loopConsultarVendaCancelada(elem.cd_transacao_cartao)
							if(cancelado) {
								this.list.splice(idx, 1)
								this.$toast.success('Forma de pagamento removida', { duration: 3000 })
							}
							if(this.comprovantePagamentoImprimir) this.imprimirComprovantePagamento(this.comprovantePagamentoImprimir)
							this.waitingCancelamento = false
							this.dialogRemoverPagamento.visible = false
						} catch (error) {
							this.$toast.error(error, { duration: 3000 })
						}
					} else {
						try {
							const response = await Caixas.removePagamentoTEF(elem.cd_transacao_cartao)
							if(([200, 201]).includes(response.status)) {
								this.list.splice(idx, 1)
								this.$toast.success('Forma de pagamento removida', { duration: 3000 })
								this.waitingCancelamento = false
								this.dialogRemoverPagamento.visible = false
							}
						} catch (error) {
							this.$toast.error(error, { duration: 3000 })
						}
					}
				}
			},
			finalizar() {
				if (! this.$refs.buscarPaciente.cd_pessoa_fisica) {
					this.$toast.error('Por favor, selecione o pagante', { duration: 3000 })
					return 0
				}

				let nm_transacao_financeira = 'Pagamento cliente'
				let cd_caixa = 8
				let cd_pagante = this.$refs.buscarPaciente.cd_pessoa_fisica
				let cd_indicador = this.$refs.buscarPaciente2.cd_pessoa_fisica

				let dataSend = {
					nm_transacao_financeira,
					cd_caixa,
					cd_pagante,
					cd_indicador: cd_indicador,
					nr_valor: this.totalDebito,
					movimento_caixa: [],
					horarios: []
				}

				if(this.cd_atendimento){
					dataSend['cd_atendimento'] = this.cd_atendimento
				}

				if (this.list.length) this.list.forEach(elem => {
					let mov = {
						nm_movimento_caixa: nm_transacao_financeira,
						cd_forma_pagamento: elem.cd_forma_pagamento,
						nr_valor: elem.nr_valor,
						nr_credito_parcelas: elem.cd_forma_pagamento == 5? elem.nr_credito_parcelas : 1,
					}
					if(elem.cd_transacao_cartao) mov['cd_transacao_cartao'] = elem.cd_transacao_cartao

					dataSend.movimento_caixa.push(mov)
				})
				else dataSend.movimento_caixa.push({
					nm_movimento_caixa: nm_transacao_financeira,
					cd_forma_pagamento: 1, // Dinheiro
					nr_valor: 0
				})

				if (this.selected) this.selected.forEach(elem => {
					dataSend.horarios.push({
						id: elem.id,
						ie_status: elem.ie_status,
						ie_valor_antigo: elem.ie_antigo ? elem.ie_antigo : false
					})
				})

				this.waitingFinalizar = true
				Caixas.registrarPagamento(dataSend).then(response => {
					if (([200, 201]).includes(response.status)) {
						this.$toast.success('Pagamento registrado', { duration: 3000 })
						this.transacaoId = response.data.id
						this.$parent.imprimir = true
					} else if (([400]).includes(response.status)) {
						if (response.data.non_field_errors)
							response.data.non_field_errors.forEach(errorMsg => {
								this.$toast.error(errorMsg, { duration: 3000 })
							})
						else if (response.data.detail) {
							if (typeof response.data.detail == 'string')
								this.$toast.error(response.data.detail, { duration: 3000 })
							else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						} else if (response.data.length) {
							response.data.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						}
					}
					this.dialogFinalizar = false
					this.waitingFinalizar = false
				})
			},
			imprimirGuias (termica=false) {
				let params = {html: termica}
				if (this.dialogOpcoesImpressao.cd_horario_agendamento) {
					params.cd_horario_agendamento = this.dialogOpcoesImpressao.cd_horario_agendamento
				} else {
					params.cd_transacao_financeira = this.transacaoId
				}

				this.dialogOpcoesImpressao.waiting = true
				Caixas.imprimirGuias(params).then(response => {
					if (response.status === 200) {
						if (termica) {
							if (!response.data.guias) return
							this.enviarImpressoraTermica(response.data.guias).then(result => {
								this.dialogOpcoesImpressao.waiting = false
								if (result) {
									this.dialogOpcoesImpressao.visible = false
									this.dialogOpcoesImpressao.cd_horario_agendamento = null
								}
								else this.$toast.error("Sem conexão com impressora térmica.")
							})

						} else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            let fileURL = URL.createObjectURL(blob)
                            let a = window.open(fileURL)
                            a.print()

							this.dialogOpcoesImpressao.waiting = false
							this.dialogOpcoesImpressao.visible = false
							this.dialogOpcoesImpressao.cd_horario_agendamento = null
                        }
                    } else {
						this.dialogOpcoesImpressao.waiting = false
						if (response.data.detail) {
							if (typeof response.data.detail == 'string') {
								this.$toast.error(response.data.detail, { duration: 3000 })
							} else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						}
					}
                })
			},
			imprimirComprovantePagamento(comprovante){
				var comp = `<div style="white-space: pre-wrap;font-family: Open Sans, Helvetica Neue, sans-serif;
					font-stretch: condensed;
					box-sizing: border-box;
					font-size: 0.7rem;
					line-height: 0.9rem;">${comprovante}<div>`;
				
				const blob = new Blob([comp], { type: "text/html; charset=utf-8" });
				let formData = new FormData();
				formData.append("file", blob);

				fetch("http://localhost:9990/imprimir", {
				method: "POST",
				body: formData,
				})
				.then(() => {
					this.$toast.success("Comprovante impresso", { duration: 3000 })
				})
				.catch(() => {
					this.$toast.error("Sem comunicação com a impressora", {duration: 3000});
				})
			},
			imprimirComprovante () {
				this.$refs.boxImprimir.imprimirComprovante()
			},
			imprimirTermica() {
				this.waitingImprimirTermica = true
				Caixas.findTransacaoPDF(this.transacaoId).then(response => {
					this.waitingImprimirTermica = false
					if (response.status == 200) {
						if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'text/html; charset=utf-8' })
                            var fd = new FormData();
                            fd.append('file', blob);
                            fetch('http://localhost:9990/imprimir', {
                                method: 'POST',
                                body: fd
                            })

                            //let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            //var fileURL = URL.createObjectURL(blob);
                            //let a = window.open(fileURL);
                            //a.print()
                            //saveAs(blob, `comprovante-transacao-${ this.transacaoId }.pdf`)
                        }
                    }
				})
			},
			async enviarImpressoraTermica(guias) {
				const enviar = async (idx) => {
					let result
					if (idx >= guias.length) return true

					let guia = guias[idx]
					const blob = new Blob([guia], { type: "text/html; charset=utf-8" })
					let formData = new FormData()
					formData.append("file", blob)

					await fetch("http://localhost:9990/imprimir", { method: "POST", body: formData })
						.then(async () => result = await enviar(++idx))
						.catch(() => result = false)

					return result
				}
				return await enviar(0)
			},
			async voltaCarrinho() {
				if(!this.cd_atendimento)
					await this.criarAtendimento()
				this.$router.push(`/agendamento/salvar/${this.cd_atendimento}/`)
			},
			atualizaCredito (val) {
				this.creditoPagante = val?.nr_saldo ?? 0
			},
			calcularAdicionar() {
				const falta = this.totalDebito - this.totalPago();
				if(this.model.valor_pago >= falta){
					this.model.nr_valor_adicionar = falta;
				}
			},
			async criarAtendimento () {
				if (!this.cd_atendimento) {
					this.criandoAtendimento = true
					const dataSend = {
						cd_pessoa_fisica: this.cd_titular,
						cd_horarios_list: _.map(this.selected, 'id')
					}
					await Agendamento.origemAtendimentoFindAllClean({ds_origem: 'APP/SITE', page: 1}).then(async response => {
						dataSend['cd_origem_atendimento'] = response.status === 200 && response.data.length == 1
						? response.data[0].id
						: null
						await Agendamento.criarAtendimento(dataSend).then(res => {
							if (res.status === 201)
								this.cd_atendimento = res.data.id
							this.criandoAtendimento = false
						})
					})
				}
			},
			buscaTitular () {
				if (this.$parent.verResumo && this.cd_titular) {
					this.$refs.buscarPaciente.limpar()
					this.$refs.buscarPaciente.filtro.selected.by = 'id'
					this.$refs.buscarPaciente.filtro.selected.value = `${this.cd_titular}`
					this.$refs.buscarPaciente.buscar()
				}
				this.list = []
			},
			addCpf(){
				this.$refs.buscarPaciente.dialogCadastro = true;
				// console.log(this.$refs.buscarPaciente.cd_pessoa_fisica)
			}
		}
	}
</script>
